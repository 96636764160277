import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaPaperPlane,
} from "react-icons/fa";
import Color from "../utils/Color";
import { Fonts } from "../utils/Fonts";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { LuPhone } from "react-icons/lu";
import { useMediaQuery } from "react-responsive";
const ContactSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const styles = {
    container: {
      marginTop: "50px",
      marginBottom: "50px",
      color: "#fff",
    },
    leftColumn: {
      borderRight: "1px solid #999",
      paddingRight: "30px",
    },
    heading: {
      fontSize: "48px",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontSize: "16px",
      marginBottom: "30px",
    },
    contactInfo: {
      display: "flex",
      alignItems: "center",
      // lineHeight: 3,

      marginBottom: "20px",
    },
    icon: {
      fontSize: "24px",
      color: "#00A4CCFF",
    },
    contactText: {
      fontSize: "16px",
    },
    mapContainer: {
      position: "relative",
      overflow: "hidden",
      paddingTop: "45.25%", // 16:9 Aspect Ratio
      marginBottom: "20px",
    },
    mapIframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "0",
    },
    jobText: {
      fontWeight: "bold",
      marginTop: "10px",
    },
    mainHeading: {
      ...Fonts.Inter,
      fontSize: "64px",
      fontWeight: 600,
      textAlign: "left",
    },
    secHeading: {
      ...Fonts.Inter,
      fontSize: "20px" ,
      fontWeight: 600,
      textAlign: "left",
    },
    text: {
      ...Fonts.Inter,
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "left",
      color: Color.fontGray,
    },
    iconsCon: {
      backgroundColor: "white",
      width: 60,
      height: 60,
      borderRadius: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Container style={styles.container}>
      <Row>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            padding:10
          }}
        >
          <div style={styles.contactInfo}>
            <div style={styles.iconsCon}>
              <IoLocationOutline style={styles.icon} />
            </div>
          </div>
          <text
            style={{
              ...Fonts.Inter,
              fontWeight: 600,
              fontSize: 32,
            }}
          >
            Find Us
          </text>
          <text style={{
              ...Fonts.Inter,
              fontWeight: 400,
              fontSize: isMobile ? 20 : 24 ,
              lineHeight: 2,
            }}>2625 Durant Ave, Berkeley, CA 94720, United States</text>
        </Col>
        <Col md={12}>
          <div style={styles.mapContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d787.4148501022325!2d-122.2558044!3d37.8682592!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80857c2f961ab005%3A0x513a508773d37000!2sResVita%20Bio!5e0!3m2!1sen!2s!4v1731722249422!5m2!1sen!2s"
              style={styles.mapIframe}
              allowFullScreen=""
              loading="lazy"
              title="map"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSection;
