import React from "react";
import {
  Box,
  Typography,
  Divider,
  Link,
  IconButton,
  Container,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Fonts } from "../../utils/Fonts";
import logo from "../../assets/resvita-bio-logo.png";
import { useMediaQuery } from "react-responsive";
import { PiLinkedinLogo } from "react-icons/pi";

const styles = {
  container: {
    padding: 15,
    backgroundColor: "black",
  },
  text: {
    color: "white",
    textAlign: "center",
    ...Fonts.Inter,
    fontSize: "14px",
    fontWeight: 400,
  },
};

const navItems = [
  { name: "About", href: "#aboutUs" },
  { name: "Team", href: "#team" },
  { name: "Advisory", href: "#Advisory" },
  { name: "News", href: "#News" },
  { name: "Careers", href: "/careers" },
  { name: "FCOI", href: "/FCOI" },
];

function Footer(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isActive } = props;
  return (
    <>
      {/* <Box sx={{...styles.container,backgroundImage: `url(${image})`,backgroundSize:'cover'}}>
        <Typography component="p" sx={styles.text}>
          ResVita Bio is pioneering skin disease treatment through engineered
          bacteria that deliver therapeutic proteins directly to affected areas.
          Our innovative approach offers targeted and sustained relief for
          conditions like Netherton Syndrome and Severe Atopic Dermatitis.
        </Typography>
      </Box> */}

      {isActive ? (
        <Box
          component="footer"
          sx={{
            backgroundColor: "black",
            color: "#fff",
            py: 3,
            paddingLeft: isMobile ? 0 : 5,
            paddingRight: isMobile ? 0 : 5,
          }}
          // paddingLeft={5}
          // paddingRight={5}
        >
          {/* Top Section with Logo and Navigation */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={2}
          >
            {/* Logo */}
            <Box
              component="img"
              src={logo}
              alt="Company-Logo"
              sx={{ height: { xs: 35, sm: 50 } }}
            />

            {/* Navigation Links */}
            <Box display={isMobile ? "" : "flex"} gap={4}>
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  underline="none"
                  color="inherit"
                  sx={{
                    fontWeight: 700,
                    fontSize: isMobile ? "10px" : "14px",
                    ...Fonts.Inter,
                    padding: 0.4,
                  }}
                >
                  {item.name}
                </Link>
              ))}
            </Box>
          </Box>

          {/* Divider */}
          <Divider sx={{ backgroundColor: "#ffffff", my: 2 }} />

          {/* Bottom Section with Copyright and Social Icons */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={3}
          >
            {/* Copyright */}
            <Typography
              variant="body2"
              style={{
                ...styles.text,
                fontSize: isMobile ? "13px" : "14px",
                textAlign: isMobile ? "left" : "",
              }}
            >
              All rights reserved ® resvitabio.com | Terms and conditions apply!
            </Typography>

            {/* Social Icons */}
            <Box display="flex" gap={2}>
              <IconButton
                href="https://www.linkedin.com/company/resvita-bio/"
                target="_blank"
                aria-label="LinkedIn"
                sx={{ color: "#fff" }}
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          component="footer"
          sx={{ backgroundColor: "black", color: "#fff", py: 3 }}
        >
          {/* Top Section with Logo and Navigation */}
          <Box
            sx={{ display: { xs: " ", sm: "flex" } }}
            justifyContent="space-between"
            alignItems="center"

            // px={3}
            // py={2}
          >
            {/* Logo */}
            <Box
              component="img"
              src={logo}
              alt="Company-Logo"
              sx={{
                height: {
                  xs: 35,
                  sm: 50,
                  paddingLeft: isMobile ? 0 : 10,
                  paddingRight: isMobile ? 0 : 10,
                },
              }}
            />
            <Typography
              variant="body2"
              style={{
                ...styles.text,
                fontSize: isMobile ? "13px" : "14px",
                textAlign: isMobile ? "center" : "",
              }}
            >
              All rights reserved ® resvitabio.com | Terms and conditions apply!
            </Typography>
            {/* Navigation Links */}
            <IconButton
              href="https://www.linkedin.com/company/resvita-bio/"
              target="_blank"
              aria-label="Instagram"
              sx={{ color: "#fff" }}
            >
              <PiLinkedinLogo />
            </IconButton>
          </Box>

          {/* Divider */}
          {/* <Divider sx={{ backgroundColor: "#ffffff", my: 2 }} /> */}

          {/* Bottom Section with Copyright and Social Icons */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={3}
          ></Box>
        </Box>
      )}
    </>
  );
}

export default Footer;
