import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import advisor1 from "../../assets/advisor1.png";
import advisor2 from "../../assets/advisor2.png";
import advisor3 from "../../assets/advisor3.png";
import advisor4 from "../../assets/advisor4.png";
import advisor5 from "../../assets/advisor5.png";

import smalled1 from "../../assets/C-2.png";
import smalled2 from "../../assets/C-5.png";
import smalled3 from "../../assets/C-4.png";
import smalled4 from "../../assets/C-1.png";
import smalled5 from "../../assets/C-3.png";
import { Fonts } from "../../utils/Fonts";
import { Container } from "react-bootstrap";

const styles = {
  heading: {
    ...Fonts.Inter,
    fontSize: "48px",
    fontWeight: 700,
    // line-height: 58.09px,
    textAlign: "left",
    // text-underline-position: from-font,
    // text-decoration-skip-ink: none,
  },
  text: {
    ...Fonts.Inter,
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "24.2px",
    textAlign: "left",
    // text-underline-position: from-font;
    // text-decoration-skip-ink: none;
  },
};
const advisors = [
  {
    name: "Dr. Lisa Beck, MD",
    title: "Dermatologist",
    description:
      "Dr. Beck is a dermatologist and internationally recognized expert in atopic dermatitis and skin barrier dysfunction. Her research was instrumental in the development of dupilumab (Dupixent), the first biologic drug approved for treating moderate-to-severe eczema.",
    image: advisor1,
    smallImg: smalled1,
  },
  {
    name: "Dr. Eric Simpson, MD, MCR",
    title: "Cardiologist",
    description:
      "Dr. Simpson is a leading dermatologist specializing in eczema and other inflammatory skin conditions. He is a world leader in researching Janus kinase (JAK) inhibitors for treating skin diseases, contributing significantly to the development of new therapies.",
    image: advisor2,
    smallImg: smalled2,
  },
  {
    name: "Gregory Went, PhD",
    title: "Oncologist",
    description:
      "Dr. Went is a biotech entrepreneur with extensive experience in building and scaling life sciences companies. As the founder and former CEO of Adamas Pharmaceuticals, he successfully led the company in bringing multiple drugs to market, including treatments for neurological disorders.",
    image: advisor3,
    smallImg: smalled3,
  },
  {
    name: "Keith Choate, MD, PhD",
    title: "Cardiologist",
    description:
      "Dr. Choate is a dermatologist and geneticist with expertise in rare genetic skin disorders. His research has uncovered the genetic causes of over a dozen skin diseases, advancing our understanding and treatment of these conditions.",
    image: advisor4,
    smallImg: smalled4,
  },
  {
    name: "Steven Lo",
    title: "Oncologist",
    description:
      "Steven Lo is a seasoned biotech executive with a strong background in corporate strategy and commercialization. He has served as CEO of multiple public pharmaceutical companies, leading them through growth and product development phases.",
    image: advisor5,
    smallImg: smalled5,
  },
];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const Advisors = () => {
  const [activeAdvisor, setActiveAdvisor] = useState(advisors[0]);

  // Determine if screen size is small or medium
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container
      sx={{
        color: "#fffff",
        padding: { xs: "20px", sm: "40px" },
        textAlign: "center",
      }}
      // style={{ marginTop: 50 }}
      id="Advisory"
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="flex-start"
      >
        {/* Advisor Info */}
        <Grid
          item
          xs={12}
          sm={6}
          mb={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              maxWidth: "600px",
              height: isSmallScreen ? 300 : 500,
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                ...styles.heading,
                fontSize: { xs: "30px", sm: "40px", md: "48px" },
                textAlign: isSmallScreen ? "center" : "",
                marginTop: { xs: 0, sm: 2, md: 10 },
                // lineHeight:1.7
                // paddingTop:10
                marginTop: { xs: 5, md: 10 },
              }}
            >
              Meet Our Advisors
            </Typography>
            <Typography
              variant="h4"
              sx={{
                ...styles.heading,
                fontSize: { xs: "24px", sm: "25px", md: "32px" },
                textAlign: "left",
                marginTop: { xs: 5, sm: 5, md: 5 },
              }}
              // sx={}}}
            >
              {activeAdvisor.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "20px",
                ...styles.text,
                fontSize: isSmallScreen ? "16px" : "20px",
                textAlign: isSmallScreen ? "justify" : "",
              }}
            >
              {activeAdvisor.description}
            </Typography>
          </Box>
        </Grid>

        {/* Large Advisor Image */}
        <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: isSmallScreen ? "auto" : "475px",
            }}
          >
            <img
              src={activeAdvisor.image}
              alt={activeAdvisor.name}
              style={{
                // width: "100%",
                maxWidth: "360px",
                height: isSmallScreen ? "350px" : "475px",

                backgroundColor: "white",
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Thumbnails of Advisors */}
      <Box
        sx={{ marginTop: isSmallScreen ? 5 : 5 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid container justifyContent="space-around">
          {advisors.map((advisor, index) => (
            <Grid item key={index}>
              <Box
                sx={{
                  width: { xs: "50px", md: "100px" },
                  height: { xs: "50px", md: "100px" },
                  borderRadius: "100%",
                  border: "2px solid #fff",
                  cursor: "pointer",
                  overflow: "hidden",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                  backgroundImage: `url(${advisor.smallImg})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                  backgroundColor: "white",
                }}
                onMouseEnter={() => setActiveAdvisor(advisor)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Advisors />
    </ThemeProvider>
  );
}

export default App;
