import React from "react";
import SkinTreatmentBanner from "../../components/AboutUS";
import Slider from "../../components/Slider";
import Start from "../../components/Start";
import ProteinTherapy from "../../components/ProtienTherapy";
import Footer from "../../components/Footer";
import HealthcareNews from "../../components/News";
import MeetOurTeam from "../../components/Team";
import Header from "../../components/Header";
import Advisors from "../../components/Advisors";

function Home() {
  return (
    <div>
      <Header />
      <Start />
      {/* <Career/> */}
      <SkinTreatmentBanner />
      <div
        style={{
          backgroundColor: "black", // Adjust colors as needed
          padding: "100px 0", // Add padding if you want spacing at the top and bottom
          color: "#FFFFFF", // Set text color to white for contrast
        }}
      >
        <Slider />
      </div>
      <div
        style={{
          background: "linear-gradient(180deg, #001B34, #000000)", // Adjust colors as needed
          padding: "50px 0", // Add padding if you want spacing at the top and bottom
          color: "#FFFFFF", // Set text color to white for contrast
        }}
      >
        {" "}
        {/* Set your desired color */}
        <MeetOurTeam />
        <div
          style={{
            backgroundColor: "black", // Adjust colors as needed
            padding: "50px 0", // Add padding if you want spacing at the top and bottom
            color: "#FFFFFF", // Set text color to white for contrast
          }}
        >
          <Advisors />
        </div>
        <div
          style={{
            background: "linear-gradient(180deg, #001B34, #000000)", // Adjust colors as needed
            padding: "20px 0", // Add padding if you want spacing at the top and bottom
            color: "#FFFFFF", // Set text color to white for contrast
          }}
        >
          <ProteinTherapy />
        </div>
      </div>
      <HealthcareNews />
      <Footer isActive />
    </div>
  );
}

export default Home;
