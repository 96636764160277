import React from "react";
import Footer from "../../components/Footer";
import Contact from "../../Contact";
import Career from "../../components/Career";
import Header from "../../components/Header";

function ContactUs() {
  return (
    <div>
      <Header />
      <Career />
      <div
        style={{
          background: "linear-gradient(180deg, #001B34, #000000)", // Adjust colors as needed
          padding: "50px 0", // Add padding if you want spacing at the top and bottom
          color: "#FFFFFF", // Set text color to white for contrast
        }}
      >
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
