import React, { useState } from "react";
import { Fonts } from "../../utils/Fonts";
import icon from "../../assets/PlusSquare.png";
import { useMediaQuery } from "react-responsive";

export default function Terms() {
  // State to track expanded sections
  const [expanded, setExpanded] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });


  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  // Styles
  const styles = {
    mainContainer: {
      width: "80vw",
      margin: "20px auto",
      fontFamily: "Arial, sans-serif",
      backgroundColor:'white'
    },
    title: {
      ...Fonts.Inter,
      fontSize: "48px",
      fontWeight: 700,
      textAlign: "center",
      marginBottom: "20px",
    },
    frame: {
      borderRadius: "8px",
      overflow: "hidden",
    },
    group: {
      padding: "10px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#f9f9f9",
      border: "2px solid #0099E699",
      borderRadius: "8px",
      padding: "15px",
      marginBottom: "8px",
      cursor: "pointer",
    },
    question: {
      ...Fonts.Inter,
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      textAlign: "center",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    description: {
      ...Fonts.Inter,
      fontWeight:400,
      margin: "10px 0",
      fontSize: "20px",
      color: "#333",
      textAlign: "justify",
      color: "black",
    },
  };

  return (
    <div style={styles.mainContainer}>
      <span style={{...styles.title,fontSize: isMobile? "38px" : "48px"}}>FCOI</span>
      <div style={styles.frame}>
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(0)}>
            <span style={styles.question}>
              What is Financial Conflict of Interest (FCOI)?
            </span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 0 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p style={{ paddingTop: 10 }}>
                The FCOI (Financial Conflict of Interest) page on the ResVita
                Bio website provides detailed information about the company's
                stringent policies and procedures for managing financial
                conflicts of interest in research. The policies are designed to
                ensure that the company operates with the highest standards of
                transparency, integrity, and ethical conduct. ResVita Bio is
                committed to safeguarding the objectivity of its research
                activities, especially those funded by the Public Health Service
                (PHS) and the National Institutes of Health (NIH)
              </p>

              <p>
                Financial conflicts of interest can arise when personal or
                institutional financial interests might affect the design,
                conduct, or reporting of research activities. By identifying and
                managing these conflicts, ResVita Bio helps ensure that its
                research outputs are unbiased, credible, and in full compliance
                with federal regulations.
              </p>
            </div>
          )}
        </div>

        {/* Policy Overview */}
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(1)}>
            <span style={styles.question}>Policy Overview</span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 1 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p>
                The Financial Conflict of Interest (FCOI) policy at ResVita Bio
                is developed in alignment with federal regulations set forth by
                the Department of Health and Human Services (42 CFR Part 50
                Subpart F and 45 CFR Part 94), with a strong emphasis on
                promoting objectivity in research. These regulations, originally
                developed in 1995 and revised in 2011, ensure that all
                individuals and organizations engaged in PHS-funded research
                maintain objectivity and prevent any financial interests from
                influencing research outcomes.
              </p>

              <p>
                ResVita Bio’s FCOI policy applies to all PHS-funded grants,
                cooperative agreements, and research contracts, except for Phase
                1 Small Business Innovation Research or Small Business
                Technology Transfer applications and/or awards. The policy
                mandates thorough financial disclosures and a comprehensive
                review process to identify and manage any potential conflicts.
                This commitment to ethical conduct is essential to protect the
                integrity of the company’s scientific research and its
                long-standing relationship with regulatory bodies, funding
                agencies, and the public.
              </p>

              <p>
                The importance of this policy goes beyond compliance—it serves
                as a foundation for trust between ResVita Bio and its
                stakeholders, including researchers, funding organizations, and
                the communities that benefit from the company’s scientific
                advancements.
              </p>
            </div>
          )}
        </div>

        {/* Add more sections similarly */}
        {/* Disclosure Requirements */}
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(2)}>
            <span style={styles.question}>Disclosure Requirements</span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 2 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p>
                Transparency is key to maintaining trust and integrity in
                research. To ensure transparency, all investigators must
                disclose their Significant Financial Interests (SFIs) to the
                designated officials at ResVita Bio. These disclosures are
                critical in identifying any potential conflicts that could
                compromise the objectivity of research.
              </p>

              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Initial Disclosure:
                  </span>{" "}
                  Investigators are required to disclose their SFIs at the time
                  of application for PHS/NIH-funded research. This ensures that
                  any financial interests are considered before the commencement
                  of research activities.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Ongoing Disclosures:
                  </span>{" "}
                  Investigators must update their disclosures annually during
                  the course of the research. Additionally, any newly acquired
                  financial interests must be disclosed within 30 days.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Specific Financial Interests:
                  </span>{" "}
                  Disclosures include both domestic and foreign financial
                  interests exceeding $5,000, such as stock ownership,
                  intellectual property rights, consulting fees, and equity
                  interests in publicly or non-publicly traded entities.
                </li>
              </ul>
              <p>
                In addition to these disclosures, investigators must report any
                reimbursed or sponsored travel that exceeds $5,000, detailing
                the sponsor, purpose, destination, and duration. This includes
                foreign entities, educational institutions, and governments,
                ensuring full transparency of all financial engagements.
              </p>
            </div>
          )}
        </div>
        {/* Managing Conflicts */}
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(3)}>
            <span style={styles.question}>Managing Conflicts of Interest</span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 3 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p>
                When a potential financial conflict of interest is identified,
                ResVita Bio employs a thorough process to manage and mitigate
                the conflict. The designated officials, Dr. Amin Zargar and Dr.
                Emilie Rennie, are responsible for reviewing all SFIs and
                determining if they present a conflict.
              </p>

              <p>
                A well-defined management plan is developed for any identified
                conflicts. The objective is to ensure that the research
                maintains its integrity, objectivity, and compliance with
                regulatory standards. Common strategies include:
              </p>

              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>Public Disclosure:</span>{" "}
                  Making conflicts of interest publicly known, especially in
                  publications, presentations, and within the research team,
                  ensures transparency and awareness.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Independent Monitoring:
                  </span>{" "}
                  In cases where the conflict could influence the outcome, an
                  independent monitor is appointed to oversee the research
                  activities and prevent bias.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Modifying Research Plans:
                  </span>{" "}
                  Adjusting the research protocol to mitigate the influence of
                  the conflict ensures that the design, conduct, or reporting of
                  the research remains unbiased.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Adjusting Personnel:
                  </span>{" "}
                  In some cases, investigators with financial interests may be
                  reassigned to prevent their involvement in specific aspects of
                  the research.
                </li>
              </ul>
              <p>
                No NIH-funded project will proceed without full compliance with
                the FCOI policy, ensuring that all conflicts are managed in
                accordance with federal guidelines.
              </p>
            </div>
          )}
        </div>
        {/*Public Accessibility */}
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(5)}>
            <span style={styles.question}>Public Accessibility</span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 5 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p>
                ResVita Bio is committed to transparency and public
                accountability. To maintain this commitment, information about
                significant financial interests related to NIH-funded research
                is made available to the public upon request. This ensures that
                the public, funding bodies, and other stakeholders can trust the
                integrity of the research being conducted.
              </p>

              <p>
                Requests for information will be responded to within five
                business days. The following details will be provided:
              </p>

              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Investigator's Name:
                  </span>{" "}
                  The individual responsible for the design, conduct, or
                  reporting of the research.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Title and Role: </span>{" "}
                  The investigator’s title and specific role in the research
                  project.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Entity Involved: </span>{" "}
                  The name of the entity where the financial interest is held.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Nature of Interest:
                  </span>
                  A description of the significant financial interest, such as
                  ownership of stocks or receipt of consulting fees.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Value:</span> The
                  approximate dollar value of the financial interest, presented
                  in specific ranges, or a statement indicating if the value
                  cannot be determined.
                </li>
              </ul>
              <p>
                No NIH-funded project will proceed without full compliance with
                the FCOI policy, ensuring that all conflicts are managed in
                accordance with federal guidelines.
              </p>
            </div>
          )}
        </div>

        {/*Training Requirements */}
        <div style={styles.group}>
          <div style={styles.header} onClick={() => toggleExpand(6)}>
            <span style={styles.question}>Training Requirements</span>
            <div style={styles.icon}>
              <img src={icon} alt="Expand Icon" />
            </div>
          </div>
          {expanded === 6 && (
            <div style={{...styles.description, fontSize:isMobile? 16:20}}>
              <p>
                Understanding the importance of compliance with FCOI
                regulations, ResVita Bio ensures that all investigators complete
                comprehensive training before engaging in PHS/NIH-funded
                research. This training covers the responsibilities of
                investigators in identifying and disclosing significant
                financial interests, as well as understanding the regulatory
                framework that governs financial conflicts of interest.
              </p>

              <p>Training is required:</p>

              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>Prior to Research:</span>{" "}
                  Investigators must complete training before beginning any
                  NIH-funded project.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Every Four Years:</span>{" "}
                  Recurring training is mandatory to keep investigators
                  up-to-date with any policy changes or revisions.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    In Special Circumstances:
                  </span>{" "}
                  Training must be completed immediately if ResVita Bio’s FCOI
                  policy is revised, a new investigator joins the team, or if an
                  investigator is found to be non-compliant with the policy.
                </li>
              </ul>
              <p>
                Investigators are required to complete the NIH FCOI tutorial and
                provide certification of completion to ResVita Bio for audit
                purposes. This ensures that all personnel are adequately
                informed about the importance of transparency and compliance in
                maintaining the integrity of their research.
              </p>
            </div>
          )}
        </div>
      </div>

      {/*Contact Information*/}
      <div style={styles.group}>
        <div style={styles.header} onClick={() => toggleExpand(7)}>
          <span style={styles.question}>Contact Information </span>
          <div style={styles.icon}>
            <img src={icon} alt="Expand Icon" />
          </div>
        </div>
        {expanded === 7 && (
          <div style={{...styles.description, fontSize:isMobile? 16:20}}>
            <p>
              For more information or specific inquiries regarding ResVita Bio’s
              Financial Conflict of Interest policy, investigators and
              interested parties can contact the designated officials directly:
            </p>

            <p>Training is required:</p>

            <ul>
              <li>
                {" "}
                <span style={{ fontWeight: "bold" }}>Dr. Amin Zargar</span> –
                azargar@resvitabio.com
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Dr. Emilie Rennie</span> –
                erennie@resvitabio.com
              </li>
            </ul>
            <p>
              These officials are responsible for overseeing the FCOI
              disclosures and are available to address any questions or concerns
              regarding compliance, disclosures, and conflict management.
            </p>
          </div>
        )}
      </div>

      {/*Call to Action*/}
      <div style={styles.group}>
        <div style={styles.header} onClick={() => toggleExpand(8)}>
          <span style={styles.question}>Call to Action: </span>
          <div style={styles.icon}>
            <img src={icon} alt="Expand Icon" />
          </div>
        </div>
        {expanded === 8 && (
          <div style={{...styles.description, fontSize:isMobile? 16:20}}>
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Download Full Policy:
                </span>{" "}
                For a detailed understanding of ResVita Bio’s FCOI policies and
                procedures, you can download the full policy document [here].
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Submit a Disclosure:</span>{" "}
                Investigators can submit their financial disclosures directly
                through [Insert Link] to ensure full compliance with ResVita
                Bio’s FCOI policy.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
