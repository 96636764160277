import React from "react";
import Slider from "react-slick";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import body from "../../assets/Vectors of Skin/Netherton-Syndrome-Small.png";
import face from "../../assets/Vectors of Skin/Atopic-Dermatitis-Small.png";
import AntiAging from "../../assets/Vectors of Skin/Anti-aging-Small.png";
import Arm from "../../assets/Vectors of Skin/Acne-vulgaris-Small.png";
import foot from "../../assets/Vectors of Skin/Chronic-wounds-Small.png";
import foot2 from "../../assets/Vectors of Skin/Diabetes-Foot-ulcers-Small.png";
import { Fonts } from "../../utils/Fonts";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const cardData = [
  {
    icon: (
      <img src={body} alt="image1" style={{ fontSize: 48, color: "#00B8D4" }} />
    ),
    title: "Netherton Syndrome",
    description:
      "A severe and rare genetic skin disorder marked by intense redness, scaling, and extremely fragile skin prone to infection and complications.",
  },
  {
    icon: (
      <img src={face} alt="image2" style={{ fontSize: 48, color: "#00B8D4" }} />
    ),
    title: "Atopic Dermatitis",
    description:
      "A widespread and often debilitating form of eczema that causes persistent itching, painful rashes, and severely dry skin.",
  },
  {
    icon: (
      <img src={Arm} alt="image3" style={{ fontSize: 48, color: "#00B8D4" }} />
    ),
    title: "Acne Vulgaris",
    description:
      "A common but potentially severe skin condition featuring inflamed pimples, deep cysts, and scarring.",
  },
  {
    icon: (
      <img
        src={AntiAging}
        alt="image5"
        style={{ color: "#00B8D4" }}
      />
    ),
    title: " Anti-Aging",
    description:
      "Progressive changes to the skin, including thinning, wrinkling, and loss of elasticity, are among the most visible and impactful signs of aging.",
  },
  {
    icon: (
      <img src={foot} alt="image3" style={{ fontSize: 48, color: "#00B8D4" }} />
    ),
    title: "Chronic Wounds",
    description:
      "Wounds with delayed healing, often complicated by underlying medical conditions and at high risk of infection",
  },
  {
    icon: (
      <img
        src={foot2}
        alt="image4"
        style={{ fontSize: 48, color: "#00B8D4" }}
      />
    ),
    title: "Diabetes Foot Ulcers",
    description:
      "Persistent and severe wounds resulting from diabetes complications, frequently leading to infection and requiring intensive care.",
  },
];

// Custom Arrow Components
function NextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: "-30px",
        transform: "translateY(-50%)",
        backgroundColor: "#1E88E5",
        color: "white",
        "&:hover": { backgroundColor: "#1565C0" },
      }}
    >
      <FaArrowRight />
    </IconButton>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: "-30px",
        transform: "translateY(-50%)",
        backgroundColor: "#1E88E5",
        color: "white",
        "&:hover": { backgroundColor: "#1565C0" },
      }}
    >
      <FaArrowLeft />
    </IconButton>
  );
}

const SkinDisorderSlider = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: !isMobile && <NextArrow />, // Hide arrows on mobile
    prevArrow: !isMobile && <PrevArrow />, // Hide arrows on mobile
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container
      style={{
        backgroundColor: "black",
        width: isMobile ? '80%':"100%",
        marginTop: 30,
        paddingLeft: isMobile ? 5 : 0,
        paddingRight: isMobile ? 5 : 0,
      }}
    >
      <Typography
        variant="h4"
        align="center"
        color="white"
        style={{
          fontWeight: 600,
          fontSize: isMobile ? "30px" : "48px",
          textAlign: "center",
          ...Fonts.Inter,
          padding: 10,
          
        }}
        gutterBottom
      >
        New Approaches To Treating Skin Disorders
      </Typography>
      <Slider {...settings}>
        {cardData.map((item, index) => (
          <Card
            key={index}
            sx={{
              maxWidth: isMobile ? "80%" : 380,
              height:isMobile ? 280: 350,
              margin: isMobile ? "0 1%" : "0 8px", // Increase gap between cards on mobile
              marginTop: 1,
              marginBottom: 1,
              backgroundColor: "#333333",
              color: "white",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
              borderTopWidth: 3,
              borderTopColor: "#005F99",
              borderTopStyle: "solid",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              gap: 10,
            }}
          >
            <CardContent sx={{ textAlign: "left", padding: 2,}}>
              {item.icon}
              <Typography
                sx={{
                  marginTop:isMobile?  0: 2,
                  textAlign: "left",
                  padding: 1,
                  ...Fonts.Inter,
                  fontWeight: 700,
                  fontSize: isMobile ? "16px" : "24px", // Adjust font size for mobile
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  padding: 1,
                  ...Fonts.Inter,
                  fontWeight: 400,
                  fontSize: isMobile ? "12px" : "19px", // Adjust font size for mobile
                  lineHeight: 1.5,
                  textAlign: "justify",
                }}
              >
                {item.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Slider>
    </Container>
  );
};

export default SkinDisorderSlider;
