import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Terms from "../../components/Terms";
import backgroundV from "../../assets/resvita.png"; // Left background image
import backgroundR from "../../assets/resvitaRight.png"; // Right background image
import { useMediaQuery } from "react-responsive";

const styles = {
  backgroundContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  backgroundImageLeft: {
    position: "absolute",
    top: "60%",
    left: "-5%",
    zIndex: "-1",
    maxWidth: "15%",
    height: "auto",
    transform: "rotate(20deg)",
  },
  backgroundImageRight: {
    position: "absolute",
    top: "10%",
    right: "-1%",
    zIndex: "-1",
    height: "auto",
    transform: "rotate(-20deg)",
  },
  contentContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    textAlign: "center",
    margin: "20px 0",
    color: "black",
  },
  termsContainer: {
    marginTop: "20px",
  },
};

function FCOI() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const is4k = useMediaQuery({ query: "(min-width: 2040px)" });  // Check for 4K screens

  return (
    <div style={styles.backgroundContainer}>
      <Header />

      {/* Background Images */}
      {/* Only show background images if the screen is not 4K */}
      {!is4k && !isMobile && (
        <>
          <img
            src={backgroundV}
            alt="Left Background Decoration"
            style={styles.backgroundImageLeft}
          />
          <img
            src={backgroundR}
            alt="Right Background Decoration"
            style={styles.backgroundImageRight}
          />
        </>
      )}

      {/* Main Content */}
      <Terms />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default FCOI;
