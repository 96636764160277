import React from "react";
import { Container } from "react-bootstrap";
// import image from "../../assets/career.png";
import Color from "../../utils/Color";
import { Fonts } from "../../utils/Fonts";
import { Box, IconButton } from "@mui/material";
import { alignPropType } from "react-bootstrap/esm/types";
import { useMediaQuery } from "react-responsive";
import image from "../../assets/image.png";
import { PiLinkedinLogo } from "react-icons/pi";

const styles = {
  container: {
    height: "30vh", // Adjusted for better responsiveness
    width: "100%",
    backgroundColor:'black',
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:'center'
  },
  heading: {
    ...Fonts.Inter,
    fontSize: "64px", // Responsive font size
    fontWeight: 700,
    textAlign: "center",
    color: Color.white,
    // paddingLeft: "5vw", // Adjusted for responsiveness
    textAlign:'center'
  },
  text: {
    ...Fonts.Inter,
    fontSize: "20px", // Responsive font size
    fontWeight: 400,
    textAlign: "center",
    color: Color.white,
    lineHeight:3
  },
};

function Career() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
    <Box style={styles.container}>
      <text style={{...styles.heading, fontSize: isMobile? '44px' : "64px",}}>Career</text>
      {/* <section> */}
      <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between', cursor :'pointer'}}>
      <IconButton
            href="https://www.linkedin.com/company/resvita-bio/"
            target="_blank"
            aria-label="Instagram"
            sx={{ color: "#fff" }}
          >
      <PiLinkedinLogo size={20} color="#0099E6" />
          </IconButton>
      <text style={{...styles.text, fontSize:isMobile? '12px':'24px', paddingLeft:isMobile ? 5: 20, }} >Please Refer To Our LinkedIn Page For Job Openings</text>
      </Box>
      {/* <section/> */}
    </Box>
      <img src={image} style={{height:'100%',width:'100%'}}/>
    </>
  );
}

export default Career;
