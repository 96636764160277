import React from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import { GoShieldCheck } from "react-icons/go";
import { AiOutlineUpload } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { Fonts } from "../../utils/Fonts";
import { Container } from "react-bootstrap";

const styles = {
  card: {},
  heading: {
    ...Fonts.Inter,
    fontSize: 16,
    fontWeight: 700,
  },
  title: {
    ...Fonts.Inter,
    fontSize: "19.5px",
    fontWeight: 400,
  },
  mainHead: {
    ...Fonts.Inter,
    fontSize: "48px",
    fontWeight: 700,
    // line-height: 58.09px;
    textAlign: "center",
    paddingBottom: 5,
    // text-underline-position: from-font;
    // text-decoration-skip-ink: none;
  },
};

const cardData = [
  {
    icon: (
      <img
        src={require("../../assets/ShieldCheck.png")}
        alt="ShieldCheck"
        size={60}
        style={{ color: "#005F99", fontSize: 20 }}
      />
    ),
    title: "Safe",
    description:
      "Designed for patient safety with a gentle, once-daily topical application, minimizing side effects",
  },
  {
    icon: (
      <img
        src={require("../../assets/Target.png")}
        alt="MonitorArrowUp"
        style={{ color: "#005F99", fontSize: 20 }}
      />
    ),
    title: "Targeted",
    description:
      "Engineered to deliver continuous, sustained therapeutic proteins directly to affected skin cells",
  },
  {
    icon: (
      <img
        src={require("../../assets/UsersThree.png")}
        alt="UsersThree"
        style={{ color: "#005F99", fontSize: 20 }}
      />
    ),
    title: "Universal",
    description:
      "Can deliver multiple therapies in a single treatment, addressing the diverse causes and complexities of skin diseases",
  },
];

export default function ContinuousProteinTherapy() {
  return (
    <Container sx={{ padding: 4 }} style={{ marginTop: 50 }}>
      <Typography
        variant="text"
        align="center"
        gutterBottom
        sx={{
          ...styles.mainHead,
          fontSize: { xs: "30px", sm: "48px" },
        lineHeight: { xs: 1.7, md: 1.78 },
        }}
      >
        Continuous Protein Therapy
      </Typography>
      <div style={{marginBottom:65}}>

      <Typography
        variant="p"
        // align="center"
        gutterBottom
        sx={{
          ...styles.mainHead,
          fontSize: { xs: "18px", sm: "18px" },
          ...Fonts.Inter,
    // fontSize: "48px",
    fontWeight: 400,
        }}
      >
      ResVita Bio technology uses genetically engineered bacteria as inert,
      living cellular factories to deliver therapeutic proteins directly to the
      skin. We call this continuous protein therapy, enabling precise, sustained
      single or multi- targeted treatments with minimal systemic side effects.
      </Typography>
      </div>
      
      <Grid container spacing={4} justifyContent="center">
        {cardData.map((item, index) => (
          <Grid item xs={10} sm={6} md={4} key={index} padding={2}>
            <Card
              sx={{
                backgroundColor: "#D5F0FE",
                padding: 3,
                textAlign: "center",
                borderRadius: 0,
                height: "100%",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    // marginTop:20
                  }}
                >
                  {item.icon}
                  <Typography
                    sx={{
                      ...styles.heading,
                      fontSize: 24,
                      marginTop: 5,
                      color: "#252B42",
                    }}
                  >
                    {item.title}
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
                <Typography
                  style={{
                    ...styles.title,
                    textAlign: "justify",
                    // lineHeight: 1.8,
                    // padding: 0.5,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
