import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Fonts } from '../../utils/Fonts';
import dnaImage from '../../assets/DNA.gif';
import { useMediaQuery } from 'react-responsive';

const styles = {
  mainContainer: {
    position: 'relative',
    margin: '0 auto',
    background: 'linear-gradient(135deg, #005F99, #033451, #131126, #1E1E1E)',
    overflow: 'hidden',
    height: { xs:'100vw',md:'100vw',lg:'70vw' ,xl:'50vw'}, // Full height for responsiveness
  },
  advancedSkinTreatments: {
    color: '#ffffff',
    ...Fonts.Helvetica,
    fontSize: { xs: '32px', sm: '50px', md: '70px', lg: '90px',  }, // Responsive font size
    fontWeight: 700,
    textAlign: {xs:'left' ,sm: 'left', md:'left'},
    lineHeight:1.15,
    marginTop: { xs: 2, md: 4 }, // Adjust spacing for responsiveness

  },
  poweredBySyntheticBiology: {
    color: '#ffffff',
    ...Fonts.Inter,
    fontSize: { xs: '18px', sm: '30px', md: '36px',lg: '40px', }, // Responsive font size
    fontWeight: 700,
    textAlign: {xs:'left' ,sm: 'left', md:'left'},
    marginTop: { xs: 2, md: 4 }, // Adjust spacing for responsiveness
  },
  skinDiseaseTreatment: {
    color: '#ffffff',
    ...Fonts.Inter,
    // fontStyle: 'italic',
    fontSize: { xs: '14px', sm: '18px', md: '20px',lg: '20px', }, // Responsive font size
    fontWeight: 400,
    textAlign: {xs:'left' ,sm: 'left', md:'left'},
    marginTop: { xs: 1, md: 2 },
    width:{  sm: 500, md: 650 }
  },
  dna: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center', // Ensure the image is always centered
    zIndex: 1,
    backgroundImage: {
      // xs: 'none',  // For extra small screens, no background image
      sm: `url(${dnaImage})` },  // For small screens and up, show the image
  },
};

export default function Start() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.dna} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'relative',
          zIndex: 2,
          height: '100%',
          paddingX: { xs: 2, md: 10 }, // Adjust padding for responsiveness
          paddingY: { xs: 2, md: 10 }, // Adjust padding for responsiveness
        }}
      >
        <Grid item xs={5} >
          <Typography variant="h1" sx={{...styles.advancedSkinTreatments, marginRight:isMobile? 0:45}}>
          LIVING SCIENCE FOR
            <br />
          LASTING THERAPIES
          </Typography>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: { xs: '80vw', md: '50vw' }, textAlign: 'left', marginTop: { xs: 3, md: 5 } }}>
            <Typography sx={styles.poweredBySyntheticBiology}>
            Powered By Synthetic Biology
            </Typography>
            <Typography sx={styles.skinDiseaseTreatment}>
              Transforming skin disease treatment with genetically engineered
              bacteria and delivering sustained, targeted therapy for lasting
              relief.
            </Typography>
          </Box>
        </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
