import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import person1 from "../../assets/person1.png";
import person2 from "../../assets/person2.png";
import person3 from "../../assets/person3.png";
import person4 from "../../assets/person4.png";
import person5 from "../../assets/person5.png";
import person6 from "../../assets/person6.png";
import person7 from "../../assets/person7.png";
import { Fonts } from "../../utils/Fonts";
import { useMediaQuery } from "react-responsive";

const TeamSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 1024px) and (max-width: 1440px)",
  });

  const teamMembers = [
    {
      name: "DR. Amin Zargar, PhD",
      title: "Chief Executive Officer",
      description:
        "Amin is the CEO and co-founder of ResVita Bio, recognized for his leadership in chemical engineering, bioengineering, and synthetic biology. Awarded the NIH Ruth L. Kirschstein Fellowship and the UC Berkeley Bakar Entrepreneurship Fellowship, he has also served as Principal Investigator on four NIH-funded projects.",
      img: person2,
    },
    {
      name: "DR. Jay Keasling, PhD",
      title: "Co-Founder & Board Member",
      description:
        "Jay, co-founder of ResVita Bio, is a professor at UC Berkeley and a pioneering figure in synthetic biology. Named Discover Magazine’s Scientist of the Year, Jay led the development of artemisinin, the first synthetic biology-produced, FDA-approved drug, which revolutionized malaria treatment. His research in metabolic engineering underpins ResVita’s therapeutic platform.",
      img: person1,
    },
    { name: "Emilie Rennie", title: "PhD, Chief of Staff", img: person6 },
    {
      name: "Andrew Hagen",
      title: "PhD, Principal Scientist II",
      img: person3,
    },
    { name: "Shyam Sah", title: "PhD, Senior Scientist II", img: person4 },
    { name: "Sarah Moradi Fard", title: "PhD, Senior Scientist I", img: person7 },

    {
      name: "Maya Stevens",
      title: "BS, Senior Research Associate",
      img: person5,
    },
  ];

  const styles = {
    container: {
      textAlign: "center",
      marginTop: "100px",
      marginBottom: "100px",
    },
    heading: {
      ...Fonts.Inter,
      fontSize: isMobile ? "30px" : "48px",
      fontWeight: 700,
      textAlign: "left",
    },
    description: {
      marginTop: "10px",
      marginBottom: "30px",
      ...Fonts.Inter,
      fontSize: isMobile ? "15px" : "24px",
      fontWeight: 400,
      textAlign: "left",
    },
    card: {
      overflow: "hidden",
      position: "relative",
      cursor: "pointer",
      borderRadius: "0",
      border: "none",
    },
    imgContainer: {
      position: "relative",
      overflow: "hidden",
      height: isMobile ? "auto" : "350px",
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "transform 0.3s ease",
      borderRadius: "0",
      border: "none",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#005F99",
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0,
      transition: "opacity 0.3s ease",
      padding: isMobile ? "10px" : "15px",
      textAlign: "left",
    },
    overlayText: {
      fontSize: isMobile ? "14px" : "20px",
      fontWeight: 600,
      padding: isMobile ? "2px" : "5px",
      ...Fonts.Inter,
      textDecorationLine: "underline",
      textAlign: "center",
      width: "100%",
    },
    title: {
      fontWeight: "500",
      fontSize: isMobile ? "12px" : "16px",
      textAlign: "center",
      lineHeight: 1,
      width: "100%",
    },
    descriptionText: {
      ...Fonts.Inter,
      fontSize: isMobile ? "10px" : "14px",
      fontWeight: 400,
      textAlign: "justify",
      marginTop: "8px",
      lineHeight: "1.4em",
      width: "100%",
    },
    imgHover: {
      transform: "scale(1.05)",
    },
  };

  return (
    <Container style={styles.container} id="team">
      <Row className="mt-4 align-items-center">
        {/* Heading and description on the left */}
        <Col sm={12} md={12} lg={6} className="d-flex flex-column">
          <p
            style={{
              ...styles.heading,
              textAlign: isMobile ? "center" : "left",
            }}
          >
            Meet Our Team
          </p>
          <p
            style={{
              ...styles.description,
              textAlign: isMobile ? "center" : "left",
            }}
          >
            The scientists pioneering breakthroughs at the intersection of
            synthetic biology and immunology.
          </p>
        </Col>

        {/* Two images on the right */}
        <Col
          sm={12}
          md={12}
          lg={6}
          className="d-flex flex-wrap justify-content-between align-items-center"
        >
          {teamMembers.slice(0, 2).map((member, index) => (
            <div
              key={index}
              style={{
                width: isTablet ? "48%" : isMobile ? "47%" : "48%",
                marginBottom: "15px", // Adjusting bottom margin
              }}
            >
              <Card style={styles.card}>
                <div
                  style={styles.imgContainer}
                  onMouseEnter={(e) => {
                    const overlay = e.currentTarget.children[1];
                    overlay.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    const overlay = e.currentTarget.children[1];
                    overlay.style.opacity = 0;
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={member.img}
                    alt={member.name}
                    style={styles.img}
                  />
                  <div style={styles.overlay}>
                    <div style={styles.overlayText}>
                      <span>{member.name}</span>
                    </div>
                    <div style={styles.title}>
                      <span>{member.title}</span>
                    </div>
                    {member.description && (
                      <div style={styles.descriptionText}>
                        {member.description}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </Col>
      </Row>

      {/* Bottom row with 5 images */}
      <Row style={{ display: "flex", justifyContent: isTablet ? 'center' : isMobile ? "flex-start" : "flex-end" }}>
        {teamMembers.slice(2).map((member, index) => (
          <Col
            key={index}
            sm={12}
            md={4}
            lg={2}
            style={{
              width: isMobile ? "50%" : "264px",
              paddingTop: "15px", // Adjust padding top
              marginBottom: "20px", // Ensuring consistent bottom spacing
            }}
          >
            <Card style={styles.card}>
              <div
                style={styles.imgContainer}
                onMouseEnter={(e) => {
                  const overlay = e.currentTarget.children[1];
                  overlay.style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                  const overlay = e.currentTarget.children[1];
                  overlay.style.opacity = 0;
                }}
              >
                <Card.Img
                  variant="top"
                  src={member.img}
                  alt={member.name}
                  style={styles.img}
                />
                <div style={styles.overlay}>
                  <div style={styles.overlayText}>
                    <span>{member.name}</span>
                  </div>
                  <div style={styles.title}>
                    <span>{member.title}</span>
                  </div>
                  {member.description && (
                    <div style={styles.descriptionText}>
                      {member.description}
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default TeamSection;
