import React from 'react'; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom'; // useLocation for current route

function Header() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const location = useLocation(); // Get current route location

  // Check if we are on the home page or other pages with smooth scroll sections
  const showScrollLinks = location.pathname === '/';
  
  // Check if we are on '/careers' or '/FCOI' to show "Home" link
  const showHomeLink = location.pathname === '/careers' || location.pathname === '/FCOI';

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img src={require('../../assets/mainLogo.png')} alt="revita-1" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ border: 'none' }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className={`${isMobile ? 'w-100 text-left' : 'ms-auto'}`} // Align left on mobile, right on larger screens
            style={{ textAlign: isMobile ? 'left' : '', }}
          >
            {/* Conditionally render scroll links only on home page */}
            {showScrollLinks && (
              <>
                <Nav.Link href="#aboutUs" className="scroll-link">About us</Nav.Link>
                <Nav.Link href="#team" className="scroll-link">Teams</Nav.Link>
                <Nav.Link href="#Advisory" className="scroll-link">Advisory</Nav.Link>
                <Nav.Link href="#News" className="scroll-link">News</Nav.Link>
              </>
            )}

            {/* Conditionally render Home link if on careers or FCOI pages */}
            {showHomeLink && (
              <Nav.Link
                as={NavLink}
                to="/"
                className={({ isActive }) => (isActive ? 'active-link' : '')}
              >
                Home
              </Nav.Link>
            )}

            {/* Page Navigation with active link styling */}
            <Nav.Link
              as={NavLink}
              to="/careers"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              Careers
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/FCOI"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              FCOI
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
